html,
body,
input,
select,
textarea {
  color: #00283e;
  background-color: #fbfbfb;
  font-family: HKGrotesk, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  color: #00283e;
  font-family: HKGrotesk, Arial, sans-serif;
  font-weight: 500;
}

h1,
.h--1 {
  font-size: 32px;
  letter-spacing: -2.3px;
  line-height: 40px;
  margin-bottom: 20px;
}

h2 {
  font-size: 25px;
  letter-spacing: -0.7px;
  margin-bottom: 35px;
}

h3 {
  font-size: 23px;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
}

h4 {
  font-size: 20px;
  letter-spacing: -0.3px;
}

h5,
h6 {
  font-size: 16px;
}

@media screen and (min-width: 480px) {
  h1 {
    font-size: 41px;
    line-height: 50px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 26px;
  }
}

p {
  font-size: 16px;
  line-height: 24px;
  padding: 25px 0;
}

p.p--tiny {
  font-size: 12px;
  line-height: 18px;
}

p.p--regular {
  font-size: inherit;
  padding: inherit;
  line-height: inherit;
}

h3.h--regular {
  color: inherit;
  font-size: 16px;
  font-weight: 800;
}

a,
a:hover,
a:visited,
a:active {
  color: #0081ed;
}

a,
a:visited,
a:active {
  text-decoration: none;
  text-transform: uppercase;
}

a:hover {
  text-decoration: underline;
}
